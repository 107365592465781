import React from 'react';

import Typography from '@material-ui/core/Typography';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Gallery from '../components/Gallery';

const Page = () => (
  <Layout noheader>
    <SEO title="Christiane & Guy" />

    <Typography variant="h2" component="h1" gutterBottom>
      Christiane & Guy
    </Typography>

    <Typography variant="h4" component="h2" gutterBottom>
      Les albums photos
    </Typography>

    <Gallery page="christiane-et-guy" />
  </Layout>
);

export default Page;
